<template>
<div :class="'tgju-widgets-row dashboard-widgets-technical-list dash-full-height ' + (is_grid_view ? 'grid-view-items' : '') ">
    <div class="market-main-tabs">
        <div v-for="(item, index) in static_market" :key="index" :class="'market-main-tab-item ' + (index == activeMarket ? 'active' : '') ">
            <router-link :to="{ name: 'MarketViews', params: { market_type: index , child_category_list: folders},}">{{ item.title }}</router-link>
        </div>
        <slot v-if="desktop">
            <div class="desktop-top-actions-icons">
                <div v-if="is_grid_view" @click="changeListView()" class="desktop-top-actions-icon"><i class="uil uil-bars"></i></div>
                <div v-if="!is_grid_view" @click="changeListView()" class="desktop-top-actions-icon"><i class="uil uil-apps"></i></div>
            </div>
        </slot>
    </div>

    <slot v-if="desktop">

        <div v-if="!is_home" class="market-main-sub-tabs w-scroll-style market-main-dynamic-sub-tabs">
            <div v-for="(item, index) in category_list" :key="index" :class="'market-main-sub-tab-item ' + (index == activeSubMarket ? 'active' : '') ">
                <router-link :to="{ name: 'MarketList', params: { market_id: item.id , child_category_list: folders},}">
                    <i :class="item.icon"></i>
                    <span>{{ item.label }}</span>
                </router-link>
            </div>
        </div>

        <div v-if="is_home" class="market-main-sub-tabs market-main-home-sub-tabs">
            <div v-for="(item, index) in category_list" :key="index" :class="'market-main-sub-tab-item ' + (index == activeSubMarket ? 'active' : '') ">
                <router-link :to="{ name: 'MarketList', params: { market_id: item.id , child_category_list: folders},}">
                    <div class="home-item-data">
                        <i :class="item.icon"></i>
                        <span>{{ item.label }}</span>
                        <p>{{ item.info }}</p>
                    </div>
                </router-link>
            </div>
        </div>

    </slot>

    <slot v-else>
        <div class="market-main-sub-tabs ">
            <div v-for="(item, index) in category_list" :key="index" :class="'market-main-sub-tab-item ' + (index == activeSubMarket ? 'active' : '') ">
                <router-link :to="{ name: 'MarketList', params: { market_id: item.id , child_category_list: folders},}">
                    <i :class="item.icon"></i>
                    <span>{{ item.label }}</span>
                </router-link>
            </div>
        </div>
    </slot>

    <div :class="'market-main-items-data ' + ( folders.length > 0 ? 'has-select' : '') ">

        <!-- <div v-if="folders.length == 0 && !this.$route.params.market_id" class="market-main-ads">
            <span v-if="desktop">تبلیغات</span>
            <a href="#">
                <img src="https://static.tgju.org/advertisement/pfkzox7u8lws.gif">
            </a>
            <a  v-if="desktop" href="#">
                <img src="https://static.tgju.org/advertisement/pfkzox7u8lws.gif">
            </a>
        </div> -->

        <router-view ></router-view>

        <div v-if="loading && !hide_static_market">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
        <slot v-if="!loading && !hide_static_market">
            <slot v-if="market_values.length">

                <div class="market-main-item" v-for="market_value in market_values" :key="market_value.id">
                    <slot v-if="market_value.id == 'breakline'">
                        <div class="breakline-row">
                            {{ market_value.title }} 
                        </div>
                    </slot>
                    <slot v-else>
                        <router-link :class="'market-main-item-row r-'+market_value.dt+''"  :to="{ name: 'MarketProfile', params: { name: market_value.item_id },}">
                            <div class="market-main-item-col item-col-right">
                                <div class="market-main-item-title">
                                    <h2 v-if="market_value.title">
                                        {{ market_value.title }}
                                    </h2>
                                    <h2 v-else-if="market_value.title_second">
                                        {{ market_value.title_second }}
                                    </h2>
                                    <h2 v-else-if="market_value.slug">
                                        {{ market_value.slug }}
                                    </h2>
                                    <h2 v-else> {{ market_value.key }}</h2>
                                </div>
                                <div class="market-main-item-time">
                                    <i class="uil uil-clock"></i>
                                    <span>{{ market_value.t }}</span>
                                </div>
                            </div>
                            <div class="market-main-item-col item-col-left">
                                <div class="market-main-item-price">
                                    {{ formatPrice(market_value.p) }}
                                </div>
                                <div :class="'market-main-item-change'+' '+ market_value.dt">
                                    {{ formatPrice(market_value.d) }} ({{ market_value.dp }} %)
                                </div>
                            </div>
                            <div class="add-to-watchlist" @click.prevent="openMarketAddToWatchListModal(market_value.id)" v-tooltip="'افزودن به واچ لیست'">
                                <i class="uil uil-file-plus-alt"></i>
                            </div>
                        </router-link>
                    </slot>
                </div>

            </slot>
            <slot v-else>
                <div class="market-main-empty"> 
                    <div class="empty-data">
                        <i class="uil uil-file"></i>
                        <span>مقداری برای نمایش وجود ندارد</span>
                    </div>
                </div>

            </slot>
        </slot>
    
    </div>
</div>
</template>

<script>
import marketStaticValues from "@/assets/static_market_page_value";
import static_market from "@/assets/static_market";
import vSelect2 from 'vue-select';
Vue.component('v-select2', vSelect2);


// این ویو برای ماژول بازار استفاده میشود
// این ویو در موبایل قرار است فقط نمایش در بیاید
export default {
    name: 'MarketView',
    data: function () {
        return {
            marketStaticValues,
            static_market,
            market_values: [],
            category_list: [],
            static_market_values: [],
            breakline_market_values: [],
            folders:[],
            defSelected:[],
            activeMarket: "local-markets",
            activeSubMarket: null,
            loading: true,
            hide_static_market: false,
            desktop: false,
            is_home: true,
            is_grid_view:false,
        }
    },    
    mounted() {
        this.is_home = !window.location.href.includes('list');

        if (Vue.prototype.$helpers.checkDevice('desktop')) {
            this.desktop= true;
        }else{
            this.desktop= false;
        }

        if (!this.$route.params.market_id) {
            this.$store.commit('setMarketlayer', 0);
        }

        this.activeMarket = this.$route.params.market_type;

        if (this.$store.state.currentMarketLvl == 3) {
            this.hide_static_market = true;
        }
        this.$root.$on('hide_static_market', (data) => {
            this.hide_static_market = data;
        });
        this.category_list = static_market[this.activeMarket].items;
        var test = this.category_list.map(item => {
            return item.id 
            }).includes(this.$route.params.market_id);
        if (test) {
            this.hide_static_market = false;
        }
        this.static_market_values = static_market[this.activeMarket].static_items;

        if (!this.hide_static_market) {
            this.getMarketValue(this.arrayToString(this.static_market_values));

        }

        if(this.$helpers.getCookie('changeListView')){
            this.is_grid_view = true;
        }

    },
    methods: {
        changeListView(){
            this.is_grid_view = !this.is_grid_view;
            if(this.is_grid_view){
                this.$helpers.setCookie('changeListView',true,'30'); 
            }else{
                this.$helpers.setCookie('changeListView',false,'-1'); 
            }

        },

        getMarketValue(ids) {
            this.loading = true;
            this.$helpers
            .makeRequest("GET", "/market/list?market_ids="+ids)
            .then((api_response) => {
                if (api_response.status == 200) {
                    this.market_values = api_response.data.response.detail;
                    this.loading = false;
                } 

                this.static_market_values = static_market[this.activeMarket].static_items;
                this.static_market_values.forEach((value, i) => {
                    if(value.id == 'breakline'){
                        this.breakline_market_values.push({id: i , name: value.label });
                    }
                });
                 this.breakline_market_values.forEach((value) => {
                    this.market_values.splice(value.id, 0, {id: 'breakline', title: value.name});
                });

            });
        },

        getSelectList(select_opt) {
            this.loading = true;
            this.$helpers
            .makeRequest("GET", "/market/category" + (select_opt == 'all' ? '' : '/' + select_opt))
            .then((api_response) => {
                var hasFolder = api_response.data.response.detail.items.map(item => {
                    return item.type
                }).includes('folder')

                if (hasFolder) {
                    this.folders = api_response.data.response.detail.items;
                    this.defSelected = {id: select_opt, label: 'نمای کلی', type: 'folder'};
                    this.folders.unshift(this.defSelected);
                } 
                this.loading = false;
            });
        },

        getMarketValueSelected(value) {
            this.loading = true;
            if(value.label == 'نمای کلی'){
                if( this.static_market[this.activeMarket].items.find(item => item.id === value.id).static_items != undefined){
                    this.getMarketValue(this.arrayToString(this.static_market[this.activeMarket].items.find(item => item.id === value.id).static_items));
                }else{
                    this.market_values = [];
                    this.loading = false;
                }
            }else{
                this.$helpers
                    .makeRequest("GET", "/market/markets-by-category/" + value.id)
                    .then((api_response) => {
                        if (api_response.status == 200) {
                            this.market_values = api_response.data.response.detail.indicators.data;
                            this.loading = false;
                        }
                });
            }
        },

        setSelected(value) {
            this.getMarketValueSelected(value);
        },

        // تغییر بازار اصلی
        switchMarkets(name){
            this.activeMarket = name;
            this.activeSubMarket = null;
            this.folders = [];
            this.category_list = static_market[name].items;
            this.static_category_list = static_market[name].static_items;
            this.getMarketValue(this.arrayToString(this.static_category_list));
        },
        // انتخاب ساب تب مارکت
        getMarkets(index,id){
            this.folders = [];
            this.getSelectList(id);
            this.activeSubMarket = index;
            if( this.static_market[this.activeMarket].items[index].static_items != undefined){
                this.getMarketValue(this.arrayToString(this.static_market[this.activeMarket].items[index].static_items));
            }else{
                this.market_values = [];
            }
        },
        // تبدیل ارایه به استرینگ برای ارسال به سرور
        arrayToString(arrayData){
            if(arrayData){
                var list = "";
                arrayData.forEach((e) => {
                    list +=  e.id + ",";
                });
                return list.substring(0, list.length - 1);
            }
        },
        // این متد برای باز کردن مودال افزودن نماد به واچ لیست و هندل کال بک آن مورد استفاده قرار میگیرد
        openMarketAddToWatchListModal(symbol) {
            let callbackEvent = this.$router.currentRoute.name + '_MarketAddToWatchList_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'MarketAddToWatchList.vue',
                config: {
                    title: 'افزودن نماد به واچ لیست',
                    smallModal: true,
                    data: {
                        symbol: symbol
                    },
                }
            };
        },

        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>
